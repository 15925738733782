/* global tw */
import React from 'react';
import SVG from '../components/SVG';
import styled from 'react-emotion';
import { UpDown, UpDownWide } from '../styles/animations';
import { colors } from '../../tailwind';
import {  ParallaxLayer } from 'react-spring/dist/addons';
import { hidden } from '../styles/utils';

const Divider = styled(ParallaxLayer)`
  ${tw('absolute w-full h-full')};
  background: ${props => props.bg};
  svg {
    fill: ${props => props.fill};
  }
  clip-path: ${props => props.clipPath};
`;

const ProjectsDivider = (props) => (
  <div>
    <Divider speed={0.1} offset={1.5} factor={2}>
    <UpDown>
      <SVG icon="box" width={6} fill={colors.white} left="85%" top="75%" />
      <SVG icon="upDown" width={8} fill={colors.teal} left="70%" top="20%" />
      <SVG icon="triangle" width={8} stroke={colors.orange} left="25%" top="5%" />
      <SVG icon="circle" className={hidden} width={24} fill={colors.white} left="17%" top="60%" />
    </UpDown>
    <UpDownWide>
      <SVG icon="arrowUp" className={hidden} width={16} fill={colors.green} left="20%" top="90%" />
      <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="30%" />
      <SVG icon="circle" width={16} fill={colors.yellow} left="70%" top="90%" />
      <SVG icon="triangle" className={hidden} width={16} stroke={colors.teal} left="18%" top="75%" />
      <SVG icon="circle" width={6} fill={colors.white} left="75%" top="10%" />
      <SVG icon="upDown" className={hidden} width={8} fill={colors.green} left="45%" top="10%" />
    </UpDownWide>
    <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
    <SVG icon="circle" width={12} fill={colors.pink} left="80%" top="60%" />
    <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
    <SVG icon="box" width={12} fill={colors.yellow} left="29%" top="26%" />
    <SVG icon="hexa" width={16} stroke={colors.red} left="75%" top="30%" />
    <SVG icon="hexa" width={8} stroke={colors.yellow} left="80%" top="70%" />
  </Divider>
  <Divider bg="#23262b" clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={0.2} offset={3} />
  <Divider speed={0.1} offset={3}>
    <UpDown>
      <SVG icon="box" className={hidden} width={6} fill={colors.blue} left="50%" top="75%" />
      <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
      <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
      <SVG icon="upDown" className={hidden} width={24} fill={colors.orange} left="80%" top="80%" />
    </UpDown>
    <UpDownWide>
      <SVG icon="arrowUp" className={hidden} width={16} fill={colors.purple} left="5%" top="80%" />
      <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />
      <SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />
      <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
    </UpDownWide>
    <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
    <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
    <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
    <SVG icon="box" width={12} fill={colors['grey-darkest']} left="20%" top="30%" />
    <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
  </Divider>
</div>
);

export default ProjectsDivider;