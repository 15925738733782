/* global tw */
import React from 'react';
import styled from 'react-emotion';
import 'typeface-cantata-one';
import 'typeface-open-sans';
import { Parallax, ParallaxLayer } from 'react-spring/dist/addons';
import SEO from '../components/SEO';
import '../styles/scss/main.scss';
import Layout from '../components/Layout';
import Contact from '../components/Contact';
import Banner from '../components/Banner';
import Projects from '../components/Projects';
import Processes from '../components/Processes';
import ContactDivider from '../components/ContactDivider';
import BannerDivider from '../components/BannerDivider';
import ProjectsDivider from '../components/ProjectsDivider';
import ProcessDivider from '../components/ProcessDivider';

const Divider = styled(ParallaxLayer)`
  ${tw('absolute w-full h-full')};
  background: ${props => props.bg};
  svg {
    fill: ${props => props.fill};
  }
  clip-path: ${props => props.clipPath};
`;

const DividerMiddle = styled(Divider)`
  clip-path: polygon(0 15%, 100% 25%, 100% 85%, 0 75%);
`;

const Index = () => (
  <React.Fragment>
      <SEO />
    <Layout>
      <Parallax pages={8}>
          <BannerDivider />
          <Banner />
        <DividerMiddle
          bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
          speed={-0.2}
          offset={1}
          factor={2}
        />
          <Projects />
          <ProjectsDivider />
        <Processes />
        <ProcessDivider />
          <Contact />
        <ContactDivider />
      </Parallax>
    </Layout>
  </React.Fragment>
);

export default Index;
