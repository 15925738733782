/* global tw */
import React from 'react';
import SVG from '../components/SVG';
import styled from 'react-emotion';
import { UpDown, UpDownWide } from '../styles/animations';
import { colors } from '../../tailwind';
import { hidden } from '../styles/utils';
import {  ParallaxLayer } from 'react-spring/dist/addons';

const Divider = styled(ParallaxLayer)`
  ${tw('absolute w-full h-full')};
  background: ${props => props.bg};
  svg {
    fill: ${props => props.fill};
  }
  clip-path: ${props => props.clipPath};
`;

const ContactDivider = (props) => (
  <Divider speed={0.1} offset={5.8}>
    <UpDown>
      <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
      <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
    </UpDown>
    <UpDownWide>
      <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />
      <SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />
      <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
    </UpDownWide>
    <UpDown>
      <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="70%" top="40%" />
      <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="25%" />
    </UpDown>
    <UpDownWide>
      <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="90%" />
      <SVG icon="circle" width={6} fill={colors.white} left="85%" top="55%" />
      <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="100%" />
    </UpDownWide>
    <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
    <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
    <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
  </Divider>
);

export default ContactDivider;