/* global tw */
import React from 'react'
import ProcessCard from '../components/ProcessCard';
import { ParallaxLayer } from 'react-spring/dist/addons';
import styled from 'react-emotion';
import { rotate } from '../styles/animations';
import triangle from '../images/triangle.svg';

const Content = styled(ParallaxLayer)`
  ${tw('p-6 md:p-12 lg:p-24 justify-center items-center flex z-50')};
`;
const Inner = styled.div`
  ${tw('w-full xxl:w-2/3 text-center lg:text-left')};
`;

const Title = styled.h1`
  ${tw('text-4xl lg:text-4xl font-serif text-white mb-8 tracking-wide relative inline-block')};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${triangle});
    position: absolute;
    background-size: 40px;
    animation: ${rotate} 4s linear infinite;
    left: -60px;
    top: 5px;
  }
`;

const ProcessWrapper = styled.div`
  ${tw('flex flex-wrap justify-between mt-8')};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(3, 3fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;



const Processes = (props) => (
  <Content speed={0.2} offset={3.8} factor={2}>
    <Inner>
      <Title>Services</Title>
        <ProcessWrapper>
          <ProcessCard title="ANALYZE" number="01."
            bg="linear-gradient(to right, #42275a 0%, #734b6d 100%)"
          >
          We will analyze your current setup and your biggest marketing problems.
          </ProcessCard>
          <ProcessCard title="IMPLEMENT" number="02."
            bg="linear-gradient(to right, #02aab0 0%, #00cdac 100%)"
          >
          Next we will handle implementation from start to finish, including configuration and third party integration.
          </ProcessCard>
          <ProcessCard title="IMPROVE" number="03."
            bg="linear-gradient(to right, #eb3349 0%, #f45c43 100%)"
          >
          We continue to improve your setup incrementally, maximizing your inside sales effort and your online media spend on attracting interested consumers.
          </ProcessCard>
        </ProcessWrapper>
    </Inner>
  </Content>
);

export default Processes;