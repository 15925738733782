/* global tw */
import React from 'react'
import { ParallaxLayer } from 'react-spring/dist/addons';
import styled from 'react-emotion';
import { rotate } from '../styles/animations';
import triangle from '../images/triangle.svg';
import { Link } from 'gatsby';

const Content = styled(ParallaxLayer)`
  ${tw('p-6 md:p-12 lg:p-24 justify-center items-center flex z-50')};
  margin-top: 10px;;
`;

const Inner = styled.div`
  ${tw('w-full xxl:w-2/3 text-center lg:text-left')};
`;

const Title = styled.h1`
  ${tw('text-4xl lg:text-4xl font-serif text-white mb-8 tracking-wide relative inline-block')};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${triangle});
    position: absolute;
    background-size: 40px;
    animation: ${rotate} 4s linear infinite;
    left: -60px;
    top: 5px;
  }
`;


const Contact = (props) => (
    <Content speed={0.2} offset={5.8} factor={1.5}>
        <Inner>
            <Title>Schedule a free consultation today</Title>
            <section id="contact">
                <div className="inner">
                    <section>
                       <form name="contact" method="POST" netlify>
                            <div className="field half first">
                                <label htmlFor="name">Name</label>
                                <input type="text" name="name" id="name" />
                            </div>
                            <div className="field half">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" />
                            </div>
                            <div className="field">
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" rows="6"></textarea>
                            </div>
                            <ul className="actions">
                                <li><input type="submit" value="Send Message" className="special" /></li>
                                <li><input type="reset" value="Clear" /></li>
                            </ul>
                            <div data-netlify-recaptcha></div>
                        </form>
                    </section>
                    <section className="split">
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-envelope"></span>
                                <h3>Email</h3>
                                <Link to="/Contact">duriansocialmedia@gmail.com</Link>
                            </div>
                        </section>
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-phone"></span>
                                <h3>Phone</h3>
                                <span>(+1) 781-999-2059</span>
                            </div>
                        </section>
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-home"></span>
                                <h3>Location</h3>
                                <span>Boston, MA<br />
                                United States of America</span>
                            </div>
                        </section>
                    </section>
                </div>
            </section>
        </Inner>
    </Content>
)

export default Contact

// <Footer>
//   &copy; 2018 by Durian Social Media.
// </Footer>