/* global tw */
import React from 'react'
import { ParallaxLayer } from 'react-spring/dist/addons';
import styled from 'react-emotion';
import { Link } from 'gatsby';

const Content = styled(ParallaxLayer)`
  ${tw('p-6 md:p-12 lg:p-24 justify-center items-center flex z-50')};
`;
const Hero = styled.div`
  ${tw('w-full xl:w-2/3')};
`;
const BigTitle = styled.h1`
  ${tw('text-5xl lg:text-6xl font-serif text-white mb-6 tracking-wide')};
  text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15);
`;

const Subtitle = styled.p`
  ${tw('text-2xl lg:text-4xl font-sans text-white  mt-8 xxl:w-3/4')};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`;

const Banner = (props) => (
    <Content speed={0.2} offset={0} factor={1}>
        <Hero>
            <BigTitle>Durian <br /> Social Media</BigTitle>
            <Subtitle>
                Helping you reach your potential customers by building a powerful
                brand legacy on Social media.
            </Subtitle>
            <ul className="actions">
                <li><Link to='/Contact' className="button next scrolly">Get Started</Link></li>
            </ul>
        </Hero>
    </Content>
);

export default Banner;